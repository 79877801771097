<template>
<v-container fluid>
  <v-stepper non-linear class="mb-3">
    <v-stepper-header>
      <v-stepper-step step="1" editable>
        Raw
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" editable>
        Filtered
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" editable>
        Assigned
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4" editable>
        Contacted
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
  <MDataTable
    title="Leads"
    :headers="[
      'Event',
      'Source',
      'Referrer',
      'First Name',
      'Last Name',
      'Email Address',
      'Contact Number',
      'Remarks',
      'Valid',
      'Assignee',
      'Contacted',
    ]"
  ></MDataTable>
  <MNote
    :text="[
      'System shall be similar to existing workflow',
      'System shall auto proccess raw lead into filtered lead',
      'User (telemarketing) can add raw lead',
      'User (telemarketing) can upload excel file',
      'User (marketing) can download excel file',
      'User can filter data by event',
      'User can filter data by source',
      'User can select the columns to show',
      'User (sales leader) can select multiple rows and assign it to another user (salesperson)',
      'User can leave remarks',
    ]"
  ></MNote>
</v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>