var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-stepper',{staticClass:"mb-3",attrs:{"non-linear":""}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","editable":""}},[_vm._v(" Raw ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","editable":""}},[_vm._v(" Filtered ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","editable":""}},[_vm._v(" Assigned ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4","editable":""}},[_vm._v(" Contacted ")])],1)],1),_c('MDataTable',{attrs:{"title":"Leads","headers":[
      'Event',
      'Source',
      'Referrer',
      'First Name',
      'Last Name',
      'Email Address',
      'Contact Number',
      'Remarks',
      'Valid',
      'Assignee',
      'Contacted' ]}}),_c('MNote',{attrs:{"text":[
      'System shall be similar to existing workflow',
      'System shall auto proccess raw lead into filtered lead',
      'User (telemarketing) can add raw lead',
      'User (telemarketing) can upload excel file',
      'User (marketing) can download excel file',
      'User can filter data by event',
      'User can filter data by source',
      'User can select the columns to show',
      'User (sales leader) can select multiple rows and assign it to another user (salesperson)',
      'User can leave remarks' ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }